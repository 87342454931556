import React, { useState, useEffect } from 'react'
import Layout from '../components/layout'
//import {Helmet} from 'react-helmet'

const GalleryPage = () => {
    const [loading, setLoading] = useState(true);
    const [imageUrls, setImageUrls] = useState([]);
    const [images, setImages] = useState([]);

    useEffect(() => {
        async function fetchImages(){
            setLoading(true);
            let resp = await fetch("https://apitest.knockoutphoto.com/v1.1/images",{
                method : 'get'
            });
            const imglist = await resp.json(resp);
            setImageUrls(imglist);
        }
        fetchImages();
    },[]);

    function toggleFullscreen(e){
        e.target.requestFullscreen();
    }

    function changeImageOnKey(e) {
        switch(e.key){
            case "ArrowLeft":
                break;
            case "ArrowRight":
                break;
            default:
                console.log(e.key);
        }
    }

    useEffect(() => {
        //setTimeout(() => {
            setLoading(false);
        if(imageUrls && imageUrls.length > 0 && !loading){
            setImmediate(() => {
                for(var i = 0; i < imageUrls.length; i++){
                    console.log(imageUrls[i]);
                    setImages(images => [ ...images, <img className="w-full cursor-pointer" src={imageUrls[i]} alt="" onClick={toggleFullscreen} onKeyPress={changeImageOnKey} />]);
                }
            })
        }
        //},2000)
        
    },[imageUrls]);


    

    let loadingImages = []
    for(var i = 0; i < 12; i++){
        loadingImages.push(<div className="animate-pulse w-96 h-96 bg-gray-400"></div>)
    }

    return(
        <Layout siteTitle="Gallery" > 
            <h1 className="py-12 font-bold text-7xl text-center">Gallery</h1>

            <div className="grid overflow-x-hidden grid-flow-row lg:grid-cols-2 xl:grid-cols-3 gap-7 sm:p-10 py-10 px-0 place-items-center">
                {
                    (loading) ? loadingImages : images
                }
            </div>
            
        </Layout>
    )
}

export default GalleryPage